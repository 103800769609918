<template>
  <div class="col-auto text-center px-1" v-for="amount in defaultAmounts" :key="amount">
    <div class="select_amount select_amount_size" :class="{selected: amount === value}" @click="useSelected(amount)">
      {{defaultCurrency}}{{amount}}
    </div>
  </div>
</template>

<script>

export default {
  props: ['modelValue'],
  emits: ['update:modelValue', 'useSelected'],
  name: 'Default Amount Select',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      async set (val) {
        await this.$emit('update:modelValue', val)
        await this.$emit('useSelected')
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    defaultAmounts () {
      if (this.isUK) {
        return ['1', '2', '5']
      } else {
        return ['20', '50', '100']
      }
    }
  },
  methods: {
    useSelected (val) {
      this.value = val
    }
  }
}
</script>

<style scoped>
.select_amount {
  color: var(--gold-color);
  font-weight: 700;
  font-size: 14px;
  background-color: var( --green-color-dark );
  cursor: pointer;
}
.select_amount:hover {
  background-color: var( --green-color );
}
.select_amount_size {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  line-height: 44px;
}
.select_amount.selected {
  color: var( --green-color-dark );
  background-color: var(--gold-color);
}

</style>
